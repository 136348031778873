/*
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
// eslint-disable-next-line import/prefer-default-export
export const PHONE_TYPES: { [key: string]: string } = {
  HOME: 'Home',
  WORK: 'Work',
  MOBILE: 'Mobile',
  FAX: 'Fax'
};
export const DEFAULT_PROVIDER_ID = '18ef2c83-bf32-4693-a986-f8f2ac51d895';

export const LANGUAGE_TYPES: { [key: string]: string } = {
  EN_US: 'English (US)',
  JA_JP: '日本語',
  EN_GB: 'English (International)',
  FR_FR: 'Français',
  DE_DE: 'Deutsch',
  PT_BR: 'Português',
  ES_ES: 'Español',
  ZH_CN: '简体中文',
  RU_RU: 'Русский',
  KO_KR: '한국어',
  IT_IT: 'Italiano',
  NL_NL: 'Nederlands',
  SV_SE: 'Svenska',
  TR_TR: 'Türkçe',
  EL_GR: 'Ελληνικά'
};

export const TIMEZONES_MAPPINGS: { [key: string]: string } = {
  GMTMinus12: 'Pacific/Fiji',
  NT: 'Pacific/Midway',
  AHST: 'Pacific/Honolulu',
  YST: 'America/Adak',
  PST: 'America/Anchorage',
  MST: 'America/Los_Angeles',
  CST: 'America/Denver',
  EST: 'America/Chicago',
  AST: 'America/New_York',
  BuenosAires: 'America/Argentina/Buenos_Aires',
  AT: 'Atlantic/South_Georgia',
  WAT: 'Atlantic/Azores',
  GMT: 'Etc/GMT',
  CET: 'Europe/Paris',
  EET: 'Europe/Bucharest',
  BT: 'Europe/Moscow',
  ZP4: 'Asia/Dubai',
  ZP5: 'Asia/Karachi',
  ZP6: 'Asia/Dhaka',
  WAST: 'Asia/Bangkok',
  CCT: 'Asia/Hong_Kong',
  JST: 'Asia/Tokyo',
  ACST: 'Australia/ACT',
  GST: 'Australia/Brisbane',
  ACDT: 'Australia/South',
  GMTPlus11: 'Antarctica/Casey',
  NZST: 'Pacific/Auckland',
  IST: 'Asia/Kolkata',
  Brasilia: 'America/Sao_Paulo',
  Santiago: 'America/Santiago',
  Bogota: 'America/Bogota',
  MexicoCity: 'America/Mexico_City'
};

export const DUPLICATE_TIMEZONES: { [key: string]: string } = {
  NZST2: 'NZST',
  BrasiliaDST: 'Brasilia',
  SantiagoDST: 'Santiago',
  MexicoCityDST: 'MexicoCity'
};

export const USER_TITLES: { [key: string]: string } = {
  UNAVAILABLE: 'Not Applicable',
  ANALYST: 'Analyst',
  ASSISTANT_SUPPORT_STAFF: 'Assistant / Support Staff',
  ASSOCIATE: 'Associate',
  BOARD_MEMBER: 'Board Member',
  CHAIRMAN_PRESIDENT_CEO: 'Chairman / President / CEO',
  C_LEVEL_OTHER: 'C-Level (Other)',
  COMPLIANCE_OFFICER: 'Compliance Officer',
  CONTROLLER_TREASURER: 'Controller / Treasurer',
  DOCTOR_PHYSICIAN: 'Doctor / Physician',
  ENGINEER_RESEARCHER_SCIENTIST: 'Engineer / Researcher / Scientist',
  GC_OTHER_COUNSEL: 'GC / Other Counsel',
  GENERAL_MANAGER: 'General Manager',
  MANAGER_SUPERVISOR: 'Manager / Supervisor',
  MANAGING_DIRECTOR_DIRECTOR: 'Managing Director / Director',
  OTHER: 'Other',
  PARTNER: 'Partner',
  PROJECT_MANAGER: 'Project Manager',
  TRADER: 'Trader',
  VP_SVP_EVP: 'VP / SVP / EVP'
};

export const USER_INDUSTRIES: { [key: string]: string } = {
  UNAVAILABLE: 'Not Applicable',
  ADVERTISING_MARKETING_PR: 'Advertising/Marketing PR',
  AEROSPACE: 'Aerospace',
  AGRICULTURE_CHEMICALS_FOREST_PRODUCTS: 'Agriculture/Chemicals/Forest Products',
  AUTOMOTIVE: 'Automotice',
  BUSINESS_PROFESSIONAL_SERVICES: 'Professional Services',
  CONSUMER_GOODS: 'Consumer Goods',
  EDUCATION: 'Education',
  ENERGY_UTILITIES: 'Energy Utilities',
  FINANCIAL_SERVICES: 'Financial Services',
  GOVERNMENT_MILITARY_PUBLIC_SERVICE: 'Government/Military/Public',
  HEALTHCARE_MEDICAL: 'Healthcare',
  HOSPITALITY_RECREATION: 'Hospitality/Recreation',
  INSURANCE: 'Insurance',
  MANUFACTURING_CONSTRUCTION_INDUSTRIALS: 'Manufacturing/Construction/Industrial',
  MEDIA_PUBLISHING_ENTERTAINMENT: 'Media/Publishing/Entertainment',
  METALS_MINING: 'Metals/Mining',
  OTHER: 'Other',
  PHARMACEUTICALS_BIOTECHNOLOGY: 'Pharmaceuticals/Biotechnology',
  REAL_ESTATE: 'Real Estate',
  RETAIL: 'Retails',
  TECHNOLOGY_COMPUTERS_ELECTRONICS: 'Technology/Computers/Electronics',
  TELECOMMUNICATIONS_NETWORKING: 'Telecommunications/Networking',
  TRAVEL_TRANSPORTATION: 'Travel/Transportation',
  SUSTAINABILITY: 'Sustainability'
};

export const fetchTimeZone = (timeZOne: string): string => DUPLICATE_TIMEZONES[timeZOne];
